<template>
  <b-modal 
    id="replay-modal"	
    ref="replay-modal" 
    size="xl" 
    hide-footer
    hide-header
    @show="getReplay"
  >
    <button type="button" class="close-btn" @click="close()"><i class="fas fa-times"></i></button>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#212121"
      :height="60"
      :width="60"
      color="#CC3636"
      loader="dots"
    />

    <iframe 
      v-if="replayUrl"
      :src="replayUrl" 
      class="modal-iframe"
      frameborder="0" 
      allowfullscreen 
      allow="autoplay; fullscreen" 
      ref="iframe"></iframe>
  </b-modal>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'

export default {
  name: 'ReplayModal',
  props: ['isShow', 'txnId'],
  data() {
    return {
      isLoading: false,
      replayUrl: null,
      startTime: null
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['replay-modal'].show()
      }else{
        this.$refs['replay-modal'].hide()
      }
    }
  },
  methods: {
    getReplay() {
      this.replayUrl = null
      this.isLoading = true
      GameService.getGameTxnInfo(this.txnId).then((response)=>{
        if(response.success) {
          this.replayUrl = response.data.url
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดข้อมูลการเล่นไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        }, ()=>{
          this.close()
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    close() {
      this.$refs['replay-modal'].hide()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#replay-modal {

  .modal-dialog {
    // width: 98vw; /* ความกว้างเท่ากับ viewport */
    height: 90vh; /* ความสูงเท่ากับ viewport */
    margin: 1vh auto; /* ไม่มี margin รอบๆ */
    // max-width: none; /* ลบข้อจำกัดของ max-width ของ Bootstrap */
  }

  .modal-content {
    height: 100%; /* ให้ modal-content มีความสูงเต็ม modal-dialog */
    border-radius: 8px; /* ลบมุมโค้งเพื่อให้พอดีกับขอบหน้าจอ */
    
    .modal-body {
      height: 100%; /* ให้ modal-body มีความสูงเต็ม modal-content */
      padding: 0; /* ลบ padding ที่มากับ Bootstrap */

      .close-btn {
        position: absolute; /* ตำแหน่งเป็น absolute */
        top: -5px; /* จากด้านบน 10px */
        right: -5px; /* จากด้านขวา 10px */
        background-color: #C63D2F; /* สีพื้นหลังของปุ่ม */
        border: none; /* ลบเส้นขอบ */
        border-radius: 50%; /* ทำให้มีรูปร่างเป็นวงกลม */
        padding: 5px 10px; /* ระยะห่างของ icon จากขอบปุ่ม */
        cursor: pointer; /* เปลี่ยน cursor เป็น pointer */
        z-index: 2; /* ให้ปุ่มอยู่ด้านบนสุด */

        i {
          color: #FFFFFF; /* สีของ icon */
        }
      }

      .modal-iframe {
        position: relative; /* ตำแหน่งเป็น relative */
        width: 100%; /* ความกว้างเต็มของ modal-body */
        height: 100%; /* ความสูงเต็มของ modal-body */
        border: none; /* ลบเส้นขอบ */
      }
    }
  }
}
</style>