<template>
  <div id="game-txn-table">
    <div class="d-flex justify-content-between align-items-center">
      <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
      <div v-else>
        ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
        ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
        <span> ถึง
          <span class="text-primary">{{dateText.end}}</span>
        </span>
      </div>

      <ul v-if="totalItems" class="pagination justify-content-end align-items-center mb-0">
        <li>
          <span class="text-secondary">แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ totalItems | amountFormat(0) }} รายการ</span>
        </li>
        <li v-if="currentPage > 1 && totalPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
          <a class="page-link rounded-0" @click="togglePage(currentPage - 1)"><i class="fas fa-angle-left"></i></a>
        </li>
        <li v-if="currentPage > 3 && totalPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
          <a class="page-link rounded-0" @click="togglePage(1)">1</a>
        </li>
        <li v-if="currentPage > 4 && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>

        <li
          v-if="totalPage > 1"
          v-for="(page, index) in pages"
          :key="index"
          class="page-item"
          :class="[{'active': page === currentPage}]"
          style="cursor: pointer; border-radius: 0;"
        ><a class="page-link rounded-0" @click="togglePage(page)">{{page}}</a></li>

        <li v-if="currentPage < (totalPage - 3) && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>
        <li v-if="currentPage < (totalPage - 2)" class="page-item" style="cursor: pointer; border-radius: 0;">
          <a class="page-link rounded-0" @click="togglePage(totalPage)">{{totalPage}}</a>
        </li>
        <li v-if="currentPage < totalPage" class="page-item" style="cursor: pointer; border-radius: 0;">
          <a class="page-link rounded-0" @click="togglePage(currentPage + 1)"><i class="fas fa-angle-right"></i></a>
        </li>
      </ul>
    </div>

    <table class="table table-sm table-bordered table-hover table-pp mb-0">
      <thead>
        <tr>
          <th width="50">ลำดับ</th>
          <th width="90">เลขที่</th>
          <th width="160">วันที่</th>
          <th width="200">เกม</th>
          <th width="100">เดิมพัน</th>
          <th width="100">คอมมิชชั่น</th>
          <th width="100">ชนะ</th>
          <th width="100">ได้-เสีย</th>
          <th width="100">สถานะ</th>
          <th>หมายเหตุ</th>
        </tr>
      </thead>
      <tbody v-if="isLoading && !items.length">
        <tr>
          <td colspan="11" class="text-center">กำลังรอข้อมูล...</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoaded && !items.length">
        <tr>
          <td colspan="11" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
      <tbody>
        <tr v-for="(item, index) in items" :key="item._id">
          <td class="text-center">{{offset+index+1}}</td>
          <td class="text-center">{{ item.txnNo }}</td>
          <td class="text-center">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
          <td class="text-center">{{ (item.game.name) }} ({{ item.game.creator.name }})</td>
          <td class="text-right text-info">{{item.dataAmount.betAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-right text-info">{{item.dataAmount.comAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-right" :class="[{'text-success': item.dataAmount.payoutAmount > 0, 'text-secondary': item.dataAmount.payoutAmount <= 0}]">{{item.dataAmount.payoutAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-right" :class="[{'text-success': item.dataAmount.sumAmount > 0, 'text-secondary': item.dataAmount.sumAmount == 0, 'text-danger': item.dataAmount.sumAmount < 0}]">{{item.dataAmount.sumAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-center"> <TxnStatus :status="item.status" /></td>
          <td class="text-center">{{ item.note }}</td>
          <!-- <td width="30" class="text-center"><i @click="viewInfo(item)" class="fa-regular fa-file-lines text-primary fa-xl txn-view-info"></i></td> -->
        </tr>
      </tbody>
      <tfoot v-if="summary.count">
        <tr>
          <th colspan="3" class="text-center">รวม</th>
          <th class="text-center">{{summary.count}} รายการ</th>
          <th class="text-right text-info">{{ summary.betAmount | amountFormat(2, '0.00') }}</th>
          <th class="text-right text-info">{{ summary.comAmount | amountFormat(2, '0.00') }}</th>
          <th class="text-right" :class="[{'text-success': summary.payoutAmount > 0, 'text-secondary': summary.payoutAmount <= 0}]">{{summary.payoutAmount | amountFormat(2, '0.00')}}</th>
          <th class="text-right" :class="[{'text-success': summary.sumAmount > 0, 'text-secondary': summary.sumAmount == 0, 'text-danger': summary.sumAmount < 0}]">{{summary.sumAmount | amountFormat(2, '0.00')}}</th>
          <th colspan="2"></th>
        </tr>
      </tfoot>
    </table>

    <ReplayModal :isShow="isShowModal" :txnId="txnId" @close="isShowModal = false" />
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import TxnStatus from './TxnStatus'
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import ReplayModal from './ReplayModal'

export default {
  name: 'TxnTable',
  components: {
    TxnStatus,
    ReplayModal
  },
  props: ['data', 'perPage', 'currentPage', 'isLoading', 'isLoaded'],
  data() {
    return {
      txnId: null,
      isShowModal: false
    }
  },
  computed: {
    items() {
      return this.data?.items || []
    },
    totalItems() {
      return this.data?.summary?.count ?? 0
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    totalPage() {
      return Math.ceil(this.totalItems/this.perPage)
    },
    pages() {
      let prevPage = this.currentPage - 2
      let nextPage = this.currentPage + 2

      if(prevPage < 1) {
        nextPage = nextPage - prevPage + 1
      }else
      if(nextPage >= this.totalPage) {
        prevPage = prevPage - (nextPage - this.totalPage)
      }

      const startPage = prevPage < 1 ? 1 : prevPage
      const endPage = nextPage > this.totalPage ? this.totalPage : nextPage

      const pages = []
      for(let i=startPage; i<=endPage; i++) {
        pages.push(i)
      }

      return pages
    },
    startRow() {
      return this.offset + 1
    },
    endRow() {
      const max = this.startRow + this.perPage - 1
      return max < this.totalItems ? max : this.totalItems
    },
    dateText() {
      if(!this.data)
        return {
          start: null,
          end: null
        }

      return {
        start: moment(this.data.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.data.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      if(!this.data)
        return ''

      return this.data.filters.total || 0
    },
    summary() {
      return this.data?.summary ?? {
        betAmount: 0,
        comAmount: 0,
        creditAmount: 0,
        payoutAmount: 0,
        sumAmount: 0,
        count: 0
      }
    }
  },
  methods: {
    togglePage(page) {
      this.$emit('page', page)
    },
    viewInfo(item) {
      this.txnId = item._id
      this.isShowModal = true
    }
  }
}
</script>
<style lang="scss" scoped>
#game-txn-table {
  .pagination {
    li {
      a {
        border-bottom: 0;
      }

      a.page-link {
        padding: 0.25rem 0.5rem;
      }
    }

    li:nth-child(2) {
      margin-left: 10px;
    }
  }

  table {
    border: 0;
    thead {
      tr {
        th {
          font-size: 85%;
          vertical-align: middle;
          text-align: center;
          font-weight: normal;
          // border-top: 0;
        }
        th:first-child {
          // border-left: 0;
        }
        th:last-child {
          // border-right: 0;
        }
      }
    }
    tbody {
      background-color: #FFFFFF;
      tr {
        td {
          vertical-align: middle;
          font-size: 85%;

          .txn-view-info {
            cursor: pointer;
          }
        }
        td:first-child {
          // border-left: 0;
        }
        td:last-child {
          // border-right: 0;
        }
      }
    }
    tfoot {
      tr {
        th {
          font-weight: normal;
        }
        th:first-child {
          // border-left: 0;
        }
        th:last-child {
          // border-right: 0;
        }
      }
    }
  }
}
</style>
